/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {




  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        // mobile menu fire up


        // $("[data-location='falkirk']").hide()
        // var falkirkbtn = $('#finder').find("[data-location='falkirk']");
        // falkirkbtn.html('Falkirk - Coming Soon').attr('href','');
        // var homefalkirk = $('.allsections').find("[data-location='falkirk']");

        // homefalkirk.find('.modulecta').html('<a class="btn btn-outline">Coming Soon</a>')


        // var falkirkbtnhead = $('#navfinder').find("[data-location='falkirk']");
        // falkirkbtnhead.html('Falkirk - Coming Soon').attr('href','');

        // var falkirkfooter = $('.section-footer').find("[data-location='falkirk']");
        // falkirkfooter.html('Falkirk - Coming Soon').attr('href','');




        $(document).ready(function(){
            $(".hamburger").click(function() {
              $(this).toggleClass("is-active");
              $('#mobilenav').toggle(240);
              // $('.brand.darkmobile').toggleClass("active");
              // $('#mobilenav').toggleClass("is-active").toggle(640);
              // $('.whitebefore').toggleClass("active");
              // $('header.navigation').toggleClass('noshadow');
            });
        });



        $('.tabbedcontent').children().hide();
        $('.tabbedcontent').children().first().show();
        $('.tabs').each(function(){
          var target = $(this).find('h3');
          target.on('click', function(){
            var goto = $(this).attr('data-target');
            $(this).siblings(target).removeClass('active');
            $(this).addClass('active');
            // alert(goto);
            $('.tabbedcontent').children().hide();
            $('.tabbedcontent').find('div[data-target="'+goto+'"]').show();
          });
        });



      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
        function hideoverflow(element){
          var element_width = element.width();
          element.css({
            // 'width' : element_width + 'px',
            'overflow' : 'hidden',
            // 'overflow-y' : 'auto'
          });
          // alert(element_width);
        }

        function setheroht(){
          
          var location_hero = $('.location-hero');
          var modulearea = $('.modulearea');
          var location_ht = location_hero.outerHeight();
         
          // equal heights module areaa
          $('.modulearea').each(function(){
            var mod = $(this).find('.module.eqheight');
            var tempmodht = 0;
            mod.each(function(){
              var mht = $(this).outerHeight();
              if (mht > tempmodht) {
                tempmodht = mht
              }
            });
            mod.css({
              'height' : tempmodht + 'px'
            });


          });

          var module_ht = modulearea.outerHeight() ;

          location_hero.css({
            'min-height' : module_ht + 'px',
            'max-height' : module_ht + 'px',
            'overflow' : 'hidden'
          });
          // alert('running')
        }

        function setnavheight() {
          var nht = $('#airthrillNav').height();

          if ( $('.page-template-template-home').length ) {
// in home page dont adjust padding on first content div after navigation
          } else {
            //  $('#airthrillNav').next('div').css({
            //     'padding-top' : nht + 'px'
            // })
          }
         

        }



        function setclassesrow() {

          var classrow = $('.section-classes .classes');

          classrow.each(function(){
            var contentht = $(this).outerHeight();
            var rownumber = $(this).find('.class-inside').attr('class');

            // alert(rownumber);
            if (rownumber === 'class-inside classrow-1' || rownumber === 'class-inside classrow-2' ) {
              $(this).find('.imgholder').css({
                'height' : contentht+30 + 'px'
              })
            }
            if (rownumber === 'class-inside classrow-3' ) {
              $(this).find('.imgholder').css({
                'height' : contentht-40 + 'px'
              })
            }

            

          });

        }

        function airthrillgallery() {

          var images = $('.gallerycol');
          var tempht = 0;
          images.each(function(){
            var ght = $(this).height();
            if (ght > tempht) {
              tempht = ght;
            }
          });

          images.each(function(){
            $(this).css({
              'height' : tempht + 'px'
            });
          });



        }


        function maphheight(){

          // var contactheight = $('.measure').outerHeight();
          // var removeheight = $('.maparea').outerHeight();

          // var mapmoduleht = contactheight-removeheight;

          // $('.mapmodule').css({
          //   'height' : mapmoduleht + 'px'
          // })


        }


         function airthrillblogheight() {

          var images = $('.blog-excerpt');
          var tempht = 0;
          images.each(function(){
            var ght = $(this).height();
            if (ght > tempht) {
              tempht = ght;
            }
          });

          images.each(function(){
            $(this).css({
              'height' : tempht + 'px'
            });
          });



        }



        function covidqa() {
          var pages = $('.page-template-template-singlepage');
          pages.each(function(){
             var question = $(this).find('h3');
             var answer = question.nextUntil('h3');
             var icon = $('<i class="fa fa-plus"></i>');

             answer.hide();
             icon.prependTo(question);

             question.click(function(){
              $(this).next(answer).toggle();
             })

          });
         
        }


        $(document).ready(function(){

          var devicewidth = $(window).width();
          // large desktop
          if (devicewidth > 1199) {
            setheroht();
            setnavheight();
            setclassesrow();
            airthrillblogheight();
            maphheight();
            covidqa();
            // airthrillgallery();

          }
          // desktop
          if (devicewidth > 991) {
            setheroht();
            setnavheight();
            setclassesrow();
            airthrillblogheight();
            maphheight()
            // airthrillgallery();

          }
          // tablet
          if (devicewidth > 780) {
             // setheroht();
             setnavheight();
             airthrillblogheight();
             maphheight()
             setclassesrow();
             // airthrillgallery();

          }
          // mobile
          if (devicewidth <= 768) {

             maphheight()



          }


          // jQuery.each( jQuery.browser, function( i, val ) {
          //   $( "<div>" + i + " : <span>" + val + "</span>" )
          //   .appendTo( document.body );
          // });


         

          

          // desktop


          // select location homepage module
          $('#finder').each(function(){
            var toggle = $(this).find('.select');
            var locations = $(this).find('.select-box');
            var locationsht = locations.outerHeight();
            locations.css({
              //'margin-top' : '-'+locationsht+'px',
            });
            toggle.click(function(){
              locations.toggleClass('open');
              $(this).toggleClass('open');
            });

            locations.find('a').each(function(){

              $(this).on('click', function(){
                var location = $(this).text();
                var href = $(this).attr('href');
                localStorage.setItem('location',location);
                localStorage.setItem('home',href);

                // return false;
              })

            });


          });




          // select location header
          $('#navfinder').each(function(){

            var toggle = $(this).find('.select');
            var locations = $(this).find('.select-box');
            var locationsht = locations.outerHeight();

            toggle.click(function(){
              locations.toggleClass('open');
              $(this).toggleClass('open');
            });

            // alert()

            locations.find('a').each(function(){

              $(this).on('click', function(){
                var location = $(this).text();
                var href = $(this).attr('href');
                localStorage.setItem('location',location);
                localStorage.setItem('home',href);

                // return false;
              })

            });

          });


          $('#setlocation').each(function(){
            var checklocation = $(this).attr('data-location');
            localStorage.setItem('location',checklocation);

            // alert(checklocation)
          });


          if(window.location.href.indexOf("glasgow") > -1) {
                // alert("Alert: Desktop!");
                $('.select').find('span').text( 'Glasgow' );
          }
          if(window.location.href.indexOf("kilbride") > -1) {
                // alert("Alert: Desktop!");
                $('.select').find('span').text( 'East Kilbride' );
          }
          if(window.location.href.indexOf("london") > -1) {
                // alert("Alert: Desktop!");
                $('.select').find('span').text( 'London' );
          }
          if(window.location.href.indexOf("perth") > -1) {
                // alert("Alert: Desktop!");
                $('.select').find('span').text( 'perth' );
          }

          // if ( localStorage.getItem('location') ) {

          //   var loc = localStorage.getItem('location');
          //   $('.select').find('span').text( loc );
          //   var homehref = localStorage.getItem('home');
          //   $('.navbar-brand').attr('href',homehref).attr('title','Air Thrill ' + loc);

          // }


          $('.floatingwrapper').each(function(){
            hideoverflow($(this));
          });


          $('.question').each(function(){
            var ques = $(this).find('h2');
            var answer = $(this).find('.answer');

            answer.hide();

            ques.click(function(){
              answer.toggle()
            })

          });



         


        });






        $(window).resize(function(){
          $('.floatingwrapper').each(function(){
            hideoverflow($(this));
          });
         


         var devicewidth = $(window).width();
          // large desktop
          if (devicewidth > 1199) {
            setheroht();
            setnavheight();
            setclassesrow();
            airthrillblogheight();
            maphheight();
            covidqa();
            // airthrillgallery();

          }
          // desktop
          if (devicewidth > 991) {
            setheroht();
            setnavheight();
            setclassesrow();
            airthrillblogheight();
            maphheight()
            // airthrillgallery();

          }
          // tablet
          if (devicewidth > 780) {
             // setheroht();
             setnavheight();
             airthrillblogheight();
             maphheight()
             setclassesrow();
             // airthrillgallery();

          }
          // mobile
          if (devicewidth <= 768) {

          }



        });
        






      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page only
        //alert (' in home only ');
        localStorage.removeItem('location');
        localStorage.removeItem('home');
        localStorage.clear();

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.





